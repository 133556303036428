/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    h2: "h2",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "trackerdetect-ui-guide",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#trackerdetect-ui-guide",
    "aria-label": "trackerdetect ui guide permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Trackerdetect UI Guide"), "\n", React.createElement(_components.h2, {
    id: "this-guide-describes-how-you-can-use-the-current-trackerdetect-ui",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#this-guide-describes-how-you-can-use-the-current-trackerdetect-ui",
    "aria-label": "this guide describes how you can use the current trackerdetect ui permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "This Guide describes how you can use the current Trackerdetect UI."), "\n", React.createElement(_components.h2, {
    id: "get-started",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-started",
    "aria-label": "get started permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get started"), "\n", React.createElement(_components.p, null, "To determine your scan scope, go to Signatu menu bar."), "\n", React.createElement(_components.p, null, "Select “Trackerdetect”."), "\n", React.createElement(_components.p, null, "Select “Site Group” to monitor multiple sites together. Set scan scope by clicking on the “plus” icon."), "\n", React.createElement(_components.p, null, "Select “Sites” to monitor a site or multiple sites separately. Set scan scope by clicking on the “plus” icon."), "\n", React.createElement(_components.p, null, "Select “Analyze” to monitor a URL. Scan scope is the single URL you enter."), "\n", React.createElement(_components.h2, {
    id: "name-the-site",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#name-the-site",
    "aria-label": "name the site permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Name the site"), "\n", React.createElement(_components.p, null, "Name the site under “New site”."), "\n", React.createElement(_components.p, null, "This will enable you to remember the site and to find the scan results."), "\n", React.createElement(_components.h2, {
    id: "describe-the-site",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#describe-the-site",
    "aria-label": "describe the site permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Describe the site"), "\n", React.createElement(_components.p, null, "Describe the site under “Description of Site” (optional)."), "\n", React.createElement(_components.h2, {
    id: "set-urls",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#set-urls",
    "aria-label": "set urls permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Set URL(s)"), "\n", React.createElement(_components.p, null, "Set URL(s) you want to scan/monitor under “Pages”."), "\n", React.createElement(_components.p, null, "This will determine the scan scope of URLs of a site."), "\n", React.createElement(_components.p, null, "Click the “plus” icon to ad URLs."), "\n", React.createElement(_components.h2, {
    id: "describe-the-url",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#describe-the-url",
    "aria-label": "describe the url permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Describe the URL"), "\n", React.createElement(_components.p, null, "Describe the URL you scan/monitor (optional)."), "\n", React.createElement(_components.p, null, "This will identify the URLs of a site by a name for your memory and retrievability."), "\n", React.createElement(_components.h2, {
    id: "delegate-responsibility",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#delegate-responsibility",
    "aria-label": "delegate responsibility permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Delegate responsibility"), "\n", React.createElement(_components.p, null, "Delegate responsibility to a specific person for Trackers on a specific page (optional) by entering the email of the responsible person under “Page responsible (optional)“."), "\n", React.createElement(_components.p, null, "The Page Responsible will be able to edit the page, and receive notifications about new and changed Trackers."), "\n", React.createElement(_components.p, null, "The pages for which the Page Responsible has responsibility are available in the Signatu menu bar, under “Trackerdetect” and “Pages”."), "\n", React.createElement(_components.h2, {
    id: "set-user-profiles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#set-user-profiles",
    "aria-label": "set user profiles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Set user profiles"), "\n", React.createElement(_components.p, null, "Set user profiles by specifying cookies (advanced)."), "\n", React.createElement(_components.p, null, "This enables you to trigger profile-triggered Trackers."), "\n", React.createElement(_components.p, null, "To scan for cookies after consent is given, you can specify the cookie value as a user that is logged in."), "\n", React.createElement(_components.h2, {
    id: "whitelist-urls",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#whitelist-urls",
    "aria-label": "whitelist urls permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Whitelist URLs"), "\n", React.createElement(_components.p, null, "You can whitelist URLs that you do not want to be flagged as unknown."), "\n", React.createElement(_components.p, null, "These are typically domains for CDNs, trusted agencies, and so forth."), "\n", React.createElement(_components.h2, {
    id: "set-monitoring-location",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#set-monitoring-location",
    "aria-label": "set monitoring location permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Set monitoring location"), "\n", React.createElement(_components.p, null, "Set monitoring location under “Scan location”."), "\n", React.createElement(_components.p, null, "This enables you to trigger geolocation-triggered Trackers."), "\n", React.createElement(_components.p, null, "Currently all crawling is executed from Ireland."), "\n", React.createElement(_components.h2, {
    id: "set-monitoring-frequency",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#set-monitoring-frequency",
    "aria-label": "set monitoring frequency permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Set monitoring frequency"), "\n", React.createElement(_components.p, null, "Set monitoring frequency under “Scan frequency”."), "\n", React.createElement(_components.p, null, "Select “Manual” or “Four times daily”."), "\n", React.createElement(_components.p, null, "This enables you to reveal and record:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "a Tracker and its Resources detected over both time and frequency, and"), "\n", React.createElement(_components.li, null, "the temporal variation of the frequency of Tracker Resources."), "\n"), "\n", React.createElement(_components.h2, {
    id: "set-alerts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#set-alerts",
    "aria-label": "set alerts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Set alerts"), "\n", React.createElement(_components.p, null, "Set alerts for Tracker events under “Events”. “Events” mean Trackers, including Trackers’ Resources."), "\n", React.createElement(_components.p, null, "Select “Never”, “All events”, “New Trackers”, “Emoved Trackers”, or “Not Approved Trackers”."), "\n", React.createElement(_components.p, null, "This enables you to receive message notifications for Trackerdetect events at your preferred frequency."), "\n", React.createElement(_components.h2, {
    id: "save-settings",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#save-settings",
    "aria-label": "save settings permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Save settings"), "\n", React.createElement(_components.p, null, "Click “Save” to save settings."), "\n", React.createElement(_components.h2, {
    id: "start-analysisscanning",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#start-analysisscanning",
    "aria-label": "start analysisscanning permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Start analysis/scanning"), "\n", React.createElement(_components.p, null, "Click “Analyze this site now” to start analysis/Scanning."), "\n", React.createElement(_components.h2, {
    id: "set-reporting",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#set-reporting",
    "aria-label": "set reporting permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Set reporting"), "\n", React.createElement(_components.p, null, "To set reporting, go to “E-MAIL NOTIFICATIONS” under “Login”/“My Account” and select “Messages” and then “Trackerdetect”."), "\n", React.createElement(_components.p, null, "To set your time-frequency for Message Notifications for Trackerdetect events, go to “Message E-mail Digest” and click on “Send e-mail messages immediately” or “Send a daily e-mail message digest”."), "\n", React.createElement(_components.h2, {
    id: "view-scan-results",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#view-scan-results",
    "aria-label": "view scan results permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "View scan results"), "\n", React.createElement(_components.p, null, "To view the results of a scanned URL, click on “Site Groups”, “Sites” or “Analyze” in Signatu menu bar."), "\n", React.createElement(_components.p, null, "Then click on the site name you have set."), "\n", React.createElement(_components.p, null, "Then click on the image of the site under “Pages (site name)“."), "\n", React.createElement(_components.p, null, "Then click on a scan performed at a specific time."), "\n", React.createElement(_components.h2, {
    id: "view-trackers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#view-trackers",
    "aria-label": "view trackers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "View Trackers"), "\n", React.createElement(_components.p, null, "To view Trackers and the resources a Tracker uses on the site, click on “Trackers”."), "\n", React.createElement(_components.p, null, "View links to Tracker’s website, Privacy Policy and email of DPO (if any), contact address etc and Resources."), "\n", React.createElement(_components.p, null, "You can copy resource information to your clipboard."), "\n", React.createElement(_components.h2, {
    id: "view-screenshot",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#view-screenshot",
    "aria-label": "view screenshot permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "View screenshot"), "\n", React.createElement(_components.p, null, "To view screenshot of the scanned URL of a Tracker, click in “Screenshot”."), "\n", React.createElement(_components.p, null, "This provides evidence of what the screenshot look like at the time of the scan."), "\n", React.createElement(_components.h2, {
    id: "view-trackers-in-iframes",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#view-trackers-in-iframes",
    "aria-label": "view trackers in iframes permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "View Trackers in iFrames"), "\n", React.createElement(_components.p, null, "To view in which iFrames Trackers are on a scanned URL, click “IFRAMES”."), "\n", React.createElement(_components.p, null, "This enables you to see who controls each IFRAME and who controls which Trackers that are in each IFRAME."), "\n", React.createElement(_components.p, null, "Click on each IFRAME to view the IP address, geolocation and country of each Tracker."), "\n", React.createElement(_components.h2, {
    id: "view-cookies",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#view-cookies",
    "aria-label": "view cookies permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "View cookies"), "\n", React.createElement(_components.p, null, "To view which cookies that are used when visiting a URL, click “COOKIES”."), "\n", React.createElement(_components.p, null, "This enables you to see which cookies that are set, whether a cookie is a permanent cookie or a session cookie, the function of a cookie, whether a cookie is set by the domain or by a Tracker."), "\n", React.createElement(_components.h2, {
    id: "view-geolocation-of-site",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#view-geolocation-of-site",
    "aria-label": "view geolocation of site permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "View Geolocation of site"), "\n", React.createElement(_components.p, null, "To view the geolocation of the website server of the site, click “GEOLOCATION”."), "\n", React.createElement(_components.p, null, "This enables you to know the country in the site server is located, and if the location is outside the EU or EEA, a transfer of end user data outside the EU or EEA."), "\n", React.createElement(_components.h2, {
    id: "view-trackergraph",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#view-trackergraph",
    "aria-label": "view trackergraph permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "View Trackergraph"), "\n", React.createElement(_components.p, null, "To view an interactive visual representation of all the Trackers on a URL and their relations, click “GRAPH ”"), "\n", React.createElement(_components.p, null, "The graph is currently a beta version."), "\n", React.createElement(_components.p, null, "The graph shows a one-image-view of all the Trackers and the relations between website and Trackers, and Tracker and Tracker."), "\n", React.createElement(_components.h2, {
    id: "view-dashboard",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#view-dashboard",
    "aria-label": "view dashboard permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "View Dashboard"), "\n", React.createElement(_components.p, null, "To view the Dashboard with all Trackers detected at a URL/site in your scan period, click on “Site Groups” or “Sites” in Signatu menu bar. (NB! Dashboard is not available under “analyze”)."), "\n", React.createElement(_components.p, null, "Then click on the image of the site you set."), "\n", React.createElement(_components.p, null, "Then click on the image for “View columns”."), "\n", React.createElement(_components.p, null, "This enables you to get:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "an overview of all detected Trackers,"), "\n", React.createElement(_components.li, null, "the status you have given a Tracker (unknown, in progress, not approved, approved),"), "\n", React.createElement(_components.li, null, "when a Tracker was last detected on the site,"), "\n", React.createElement(_components.li, null, "an indication of the Tracker purpose/category (advertising, social etc),"), "\n", React.createElement(_components.li, null, "the Tracker location,"), "\n", React.createElement(_components.li, null, "your indication that you have signed a Data Processing or joint-controller Agreement with the Tracker,"), "\n", React.createElement(_components.li, null, "your indication of who is responsible for this Tracker in your organisation, and"), "\n", React.createElement(_components.li, null, "your comments."), "\n"), "\n", React.createElement(_components.p, null, "(This Dashboard will be extended with parameters/checks for authorising a Tracker on the site and for meeting other GDPR requirements."), "\n", React.createElement(_components.h2, {
    id: "edit-dashboard",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#edit-dashboard",
    "aria-label": "edit dashboard permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Edit Dashboard"), "\n", React.createElement(_components.p, null, "To edit the Dashboard, select a Tracker and then click on “EDIT DETAILS”."), "\n", React.createElement(_components.h2, {
    id: "search-tracker-information",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#search-tracker-information",
    "aria-label": "search tracker information permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Search Tracker information"), "\n", React.createElement(_components.p, null, "To search for Tracker information, click on the icon for “Search”."), "\n", React.createElement(_components.h2, {
    id: "export-tracker-information",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#export-tracker-information",
    "aria-label": "export tracker information permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Export Tracker information"), "\n", React.createElement(_components.p, null, "To export Tracker information, click on the icon for “Download CSV”."), "\n", React.createElement(_components.h2, {
    id: "print-tracker-information",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#print-tracker-information",
    "aria-label": "print tracker information permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Print Tracker information"), "\n", React.createElement(_components.p, null, "To print Tracker information, click on the icon for “Print”."), "\n", React.createElement(_components.h2, {
    id: "view-columns-with-tracker-information",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#view-columns-with-tracker-information",
    "aria-label": "view columns with tracker information permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "View columns with Tracker information"), "\n", React.createElement(_components.p, null, "To view different columns with Tracker information, click on the icon for “View Columns”."), "\n", React.createElement(_components.h2, {
    id: "filter-dashboard",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#filter-dashboard",
    "aria-label": "filter dashboard permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Filter Dashboard"), "\n", React.createElement(_components.p, null, "To filter Dashboard, click on the icon for “Filter Table”."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
